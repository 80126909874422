.dropdown-container {
  text-align: left;
  border: 1px solid #ccc;
  position: relative;
  width: 296px;
  // height: fit-content;
  //min-width: 296px;
  //max-width: 296px;
  border-radius: 5px;
  font-size: medium;
}

.dropdown-input {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
}

.dropdown-menu {
  position: absolute;
  transform: translateY(4px);
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: auto;
  max-height: 150px;
  background-color: #fff;
  z-index: 10;
  width: inherit;
  color: gray;
}

.dopdown-item {
  padding: 5px;
  margin-left: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #6955ff;
  -webkit-text-fill-color: white;
}

.dropdown-item.selected {
  background-color: #6955ff;
  color: #fff;
}

.dropdown-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.dropdown-tag-item {
  /* background-color: #c4bfff; */
  background-color: #6955ff;
  padding: 0px 4px;
  border-radius: 2px;
  color: white;
  display: flex;
  align-items: center;
}

.dropdown-tag-close {
  display: flex;
  align-items: center;
  cursor: pointer;
}
