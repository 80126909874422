.App {
  &__link {
    padding: 4px 15px;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 10px;
    padding: 4px 12px;
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    margin: 0px 7px 5px 10px;
    svg {
      margin-right: 4px;
      path,
      circle {
        transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        stroke: #dedede;
      }
    }

    &:hover {
      cursor: pointer;
      background-color: #dedede;
      color: #000000;
      svg {
        path,
        circle {
          stroke: var(--primary);
        }
      }
    }
  }

  &__link.active {
    color: #000000;

    svg {
      path,
      circle {
        stroke: var(--primary);
      }
    }
  }
}
