.EmailInviteForm {
  &--base {
    color: #565656;
    font-family: "Inter", serif;
    font-size: 14px;
    line-height: 1.7;
    font-weight: 500;
    min-width: 500px;
  }

  &--body {
    background-color: #eaeaea;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  &--wrapper {
    background-color: white;
    width: 500px;
    padding: 2rem;
    box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
  }

  &--input {
    // width: 87%;
    flex-grow: 1;
    margin-right: 15px;
    height: 40px;
    padding: 0 1rem;
    margin-top: 1rem;
    //box-sizing: border-box;
    font: inherit;
    border-radius: 0.5rem;
    border: 1px solid #d4d5d6;
    color: black;
  }

  &--input:focus {
    border-color: cornflowerblue;
    outline: none;
  }

  &--input.has-error {
    border-color: tomato;
  }

  &--error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }

  &--success {
    margin: 0;
    font-size: 90%;
    color: green;
  }

  &--tag-item {
    background-color: #877cff;
    color: #ffffff;
    font-size: 14px;
    border-radius: 30px;
    height: 30px;
    padding: 0 4px 0 1rem;
    display: inline-flex;
    align-items: center;
    margin: 0 0.3rem 0.3rem 0;
    margin-top: 20px;
  }

  &--tag-item > .button {
    background-color: #ffffff;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;

    // &.button:hover {
    //   color: #877cff;
    // }
  }
}
