.Companies {
  &__btn {
    svg {
      path {
        stroke: #000000;
      }
    }
  }

  &__group {
    max-width: calc(100% - 70px);
  }
}

.Table__Companies {
  .Table__row {
    gap: 10px;
    padding: 0 24px;

    .Table__cell:nth-child(9) {
      padding: 0;
    }
  }
  .Table__header {
    height: 63px;
    padding: 0 24px;
    gap: 10px;
    .Table__cell:nth-child(1) {
      padding-left: 0px;
    }
    .Table__cell:nth-child(2) {
      padding-left: 0px;
    }

    .Table__cell:nth-child(3) {
      padding-left: 0px;
    }
    .Table__cell:nth-child(4) {
      padding-left: 0px;
    }
    .Table__cell:nth-child(5) {
      padding-left: 0px;
    }
    .Table__cell:nth-child(6),
    .Table__cell:nth-child(7),
    .Table__cell:last-child {
      justify-content: start !important;
    }
  }

  .Table__content {
    .Table__row {
      gap: 10px;
      padding: 0 24px;
      &:hover {
        .Companies__name {
          color: var(--primary);
          transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        }
      }
    }
  }
}

.Dropdown__Companies {
  .Dropdown__menu {
    right: unset;
    left: 0;
    transform: translate3d(-100%, -100%, 0);
    @media (min-width: 1700px) {
      left: 50%;
      transform: translate3d(-50%, calc(-100% - 8px), 0);
    }
  }
}
.Dropdown__Submenu {
  .Dropdown__trigger {
    all: unset;
  }

  .Dropdown__menu {
    top: 0;
    right: 0;
    transform: translate3d(calc(100% + 10px), 0, 0);
    height: 103px;
    overflow: auto;
    outline: 0.3px solid #000000;
    box-shadow: unset;

    @media (min-width: 1700px) {
      left: unset;
      transform: translate3d(calc(100% - 4px), 0, 0);
    }
  }

  .Dropdown__iconRight {
    svg {
      width: 16px;
      height: 16px;
    }
    margin: 0 0 0 4px;
  }

  .Dropdown__menu {
    overflow-x: hidden;
    max-width: 102px;
  }

  .Dropdown__item,
  .Dropdown__label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
