.Table {
  background-color: #fff;

  &__row {
    padding: 0 53px 0 65px;
    border-bottom: 0.7px solid #c4c4c4;
    height: 72px;
    gap: 24px;
    &:hover {
      background-color: #d9d9d966;
    }
  }
  &__header {
    padding: 0 0px 0 0px;
    height: 63px;
    border-bottom: 0.7px solid #c4c4c4;
    & > div {
      padding-top: 8px;
    }
  }
  &__cell {
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    justify-content: center;
    text-overflow: hidden;
  }
  &__content {
    // min-height: 432px;
  }
}
