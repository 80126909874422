@font-face {
  font-family: "Paralucent";
  src: url("./fonts/Paralucent-Bold.otf") format("opentype");
  font-weight: 700;
}

@font-face {
  font-family: "Paralucent";
  src: url("./fonts/Paralucent-Medium.otf") format("opentype");
  font-weight: 500;
}

:root {
  --secondary: #6e6e6e;
  --primary: #6955ff;
}

body {
  background: #f8f8fe;
  margin: 0;
  font-family: "Inter", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.Title {
  font-family: "Paralucent", sans-serif;
}

.IconBtn {
  svg {
    path {
      transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    }
  }
  &:hover {
    svg {
      path {
        stroke: var(--primary);
      }
    }
  }
}

.fadeIn {
  animation: fadeIn 250ms forwards cubic-bezier(0.4, 0, 0.2, 1);
}

.fadeOut {
  animation: fadeOut 250ms forwards cubic-bezier(0.4, 0, 0.2, 1);
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.Scrollbar::-webkit-scrollbar {
  background-color: #fff;
  width: 14px;
  height: 14px;
}

/* background of the scrollbar except button or resizer */
.Scrollbar::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.Scrollbar::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.Scrollbar::-webkit-scrollbar-button {
  display: none;
}

.Scrollbar--sm::-webkit-scrollbar {
  background-color: #fff;
  width: 7px;
  height: 7px;
}

/* background of the scrollbar except button or resizer */
.Scrollbar--sm::-webkit-scrollbar-track {
  background-color: #fff;
}

/* scrollbar itself */
.Scrollbar--sm::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 8px;
  border: 2px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
.Scrollbar--sm::-webkit-scrollbar-button {
  display: none;
}

/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
