.Sidebar {
  width: 254px;
  background-color: #fff;
  $base: &;

  &__link.active {
    color: var(--primary);

    svg {
      path,
      circle {
        stroke: var(--primary);
      }
    }
    background: #e6e6e6;
    border-radius: 0.5rem;
  }

  &__link {
    padding: 0 5px;
    margin: 8px 10px;
    transition: color 0.1s cubic-bezier(0.4, 0, 0.2, 1);

    svg {
      margin-right: 14px;
      path {
        transition: stroke 0.1s cubic-bezier(0.4, 0, 0.2, 1);
        stroke: var(--secondary);
      }
    }

    &:hover {
      svg {
        path,
        circle {
          stroke: var(--primary);
        }
      }
      background: #e6e6e6;
      border-radius: 0.5rem;
    }
  }
}
