.Dropdown {
  &__expand-left {
    direction: rtl;
  }
  &__expand-right {
    direction: ltr;
  }
  &__trigger {
    line-height: 1;
    transition: background-color 0.3s;
    &--active,
    &:hover {
      background-color: rgba(135, 124, 255, 0.18);
    }
  }

  &__menu {
    padding: 0px 0;
    top: 0;
    background: #ffffff;
    box-shadow: 1px 1px 4px rgba(135, 124, 255, 0.4);
    border-radius: 5px;
    opacity: 0;
    min-width: 102px;
    transform: translate3d(0%, 28px, 0);
  }

  &--bottom {
    .Dropdown__menu {
      top: unset;
      bottom: 0;
      transform: translate3d(0, calc(100% + 8px), 0);
    }
  }

  &--left--outside {
    .Dropdown__menu {
      right: unset;
      left: 0;
      transform: translate3d(-100%, -100%, 0);
    }
  }

  &--right {
    top: 0;
    right: 0;
    transform: translate3d(0, calc(-100% - 8px), 0);
  }

  &--right--outside {
    .Dropdown__menu {
      left: unset;
      right: 0;
      transform: translate3d(100%, -100%, 0);
    }
  }

  &--bottom--right {
    .Dropdown__menu {
      left: unset;
      top: unset;
      right: 0;
      bottom: 0;
      transform: translate3d(0, calc(100% + 8px), 0);
    }
  }

  &__icon {
    margin-right: 4px;
    svg {
      width: 10px;
      height: 10px;
      path {
        transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        stroke: var(--secondary);
      }
    }
  }

  &__item {
    padding: 3px 5px;
    margin: 0 0px;
    transition: color 0.3s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    white-space: nowrap;
    border-radius: 5px;
    direction: ltr;

    &:hover,
    &--active {
      background-color: rgba(105, 85, 255, 0.7);
      border-radius: 5px;
      color: #fff !important;
      svg {
        path {
          stroke: #fff;
        }
      }
    }
  }
}
