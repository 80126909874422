.NewBatch {
  &__modal {
    position: relative;
    max-height: calc(100vh - 270px);
  }
}

.Modal--NewBatch {
  .Modal__inner {
    max-width: 478px !important;
  }
}
