.Input {
  &--field {
    padding: 3px 8px;
    flex-grow: 1;
    border: 2rem;
    border-color: #c4c4c4;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
    // box-shadow: 1px 1px 0px 0px lightgrey;
    min-height: 41px;
    width: 90%;
    font-family: "Inter";
    font-size: 10px;
    resize: none;
    border-style: solid;
    margin-bottom: 5px;
  }

  &--field:focus {
    outline: none;
  }

  &--field:focus::placeholder {
    color: transparent;
  }
}
