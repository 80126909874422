.Dropdown--Group {
  .Dropdown__menu {
    transform: translate3d(-30%, calc(-100% - 8px), 0);
  }
  @media (min-width: 1630px) {
    .Dropdown__menu {
      left: unset;
      right: 0;
      transform: translate3d(100%, -100%, 0);
    }
  }
}
