.Home {
  &__icon {
    svg {
      width: 28px;
      height: 28px;
    }
  }
}

.Home__card {
  transition: box-shadow 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  svg {
    path,
    circle {
      stroke: var(--primary);
    }
  }

  &:hover {
    box-shadow: 0px 2px 2px rgb(0 0 0 / 25%);
  }
}
