.Comment {
  &--divider {
    margin: 10px 0px 10px 0px;
    color: #c4c4c4;
  }

  &--input {
    color: black;
    background-color: #f5f5f5;
    width: 100%;
    padding-left: 3px;
    padding-right: 10px;
    margin-top: 5px;
  }

  &--input:focus {
    outline: none;
  }

  &--button {
    width: 19px;
    height: 19px;
    margin: 0 10px 0px 0;
    cursor: pointer;
  }

  &--user-icon {
    border-radius: 1rem;
    font-size: x-small;
    width: 19px;
    height: 19px;
    border: 1px solid #6955ff;
    background-color: white;
    color: black;
    margin: 0 10px 0px 0;
    text-align: center;
    font-family: "Inter";
    line-height: 17px;
  }
}
