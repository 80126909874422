.ProfileCard {
  &--skeleton-info {
    position: relative;
    width: 40%;
    min-width: 140px;
    height: 10px;
    margin-bottom: 10px;
    margin-top: 5px;

    background: #d9d9d9;
    border-radius: 4px;
  }

  &--skeleton-user {
    width: 100%;
    height: 100%;
    justify-items: center;
    align-items: center;
    max-width: 200px;
    max-height: 200px;
    min-width: 100px;
    min-height: 100px;
  }
  &--skeleton-name {
    position: relative;
    width: 40%;
    height: 10px;
    margin-bottom: 10px;
    margin-top: 5px;

    background: #d9d9d9;
    border-radius: 4px;
  }

  &--logo {
    object-fit: fill;
    svg {
      margin-right: 14px;
      path,
      circle {
        transition: stroke 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        stroke: var(--secondary);
      }
      circle {
        fill: var(--secondary);
      }
    }
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -135px 0;
    }
    100% {
      background-position: 135px 0;
    }
  }

  &--animation {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8 linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    // background-size: 100% 100%;
    // height: 4px;
    border-radius: 4px;
    // position: relative;
  }
}
