.EmailEditor {
  &-input {
    border: none;
    height: 95%;
    width: 100%;
    resize: none;
  }
  &-input:focus {
    outline: none;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
