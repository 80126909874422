.BatchGenerate {
  &__card {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }
}

.Table--BatchGenerate {
  .Table__row {
    padding: 0 29px 0 65px;
  }
}
