.ContactDetails {
  &__card {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  }

  &__copy {
    transform: rotate3d(0, 1, 0, 180deg);
  }

  &__save {
    svg {
      path {
        stroke: var(--primary);
      }
    }
  }
}
