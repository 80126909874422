.Prospect {
  &--default_top {
    position: relative;
    width: 125px;
    height: 4px;
    margin-bottom: 10px;
    margin-top: 5px;

    background: #d9d9d9;
    border-radius: 4px;
  }
  &--default_bottom {
    position: relative;
    width: 98px;
    height: 4px;
    margin-top: 5px;

    background: #d9d9d9;
    border-radius: 4px;
  }

  @keyframes placeHolderShimmer {
    0% {
      background-position: -125px 0;
    }
    100% {
      background-position: 125px 0;
    }
  }

  &--animation {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8 linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
    background-size: 125px 125px;
    height: 4px;
    position: relative;
  }

  &--background-masker {
    background-color: #fff;
    position: absolute;
  }
}
