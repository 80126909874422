.Button {
  &--primary {
    background: linear-gradient(180deg, #6955ff 0%, #877cff 100%) !important;
  }

  &:disabled,
  &--disabled {
    background: #d9d9d9 !important;
    color: var(--secondary);

    &:hover {
      box-shadow: none !important;
      cursor: default !important;
    }
  }
}
