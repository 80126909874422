.EmailForm {
  &--base {
    flex-direction: column;
    min-height: 100%;
    align-content: center;
    max-width: 400px;
    box-shadow: none;
  }
  &--option {
    padding: 0px 10px 5px 40px;
  }
  &--label {
    font-family: "Inter";
    padding-left: 5px;
    font-size: 15px;
    font-weight: 450;
  }
  &--input {
    padding: 3px 8px;
    margin-top: 5px;
    border: 2rem;
    border-color: #c4c4c4;
    border-width: 1px;
    border-radius: 10px;
    min-height: 41px;
    width: 90%;
    font-family: "Inter";
    font-size: 12px;
    resize: none;
    border-style: solid;
  }
  &--input:focus {
    outline: none;
  }
  &--input:focus::placeholder {
    color: transparent;
  }

  &--button {
    position: relative;
    margin-bottom: 20px;
    height: 37px;
    width: 90%;
  }

  &--select {
    padding: 3px 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    border: 2rem;
    border-color: #c4c4c4;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    height: 41px;
    width: 90%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-size: 12px;
    padding-left: 8px;
  }
  &--select:focus {
    outline: none;
  }
  &--select::-ms-expand {
    display: none; /* Hide the default arrow in Internet Explorer 10 and Internet Explorer 11 */
  }

  &--list {
    margin-top: 0px;
    border: 2rem;
    border-color: #c4c4c4;
    border-width: 1px;
    border-radius: 10px;
    border-style: solid;
    box-shadow: 1px 1px 0px 0px lightgrey;
    min-height: 41px;
    max-height: 175px;
    margin-bottom: 35px;
    width: 90%;
    font-family: "Inter";
    font-size: 10px;
    resize: none;
  }
  &--item {
    padding: 10px 0px 0px 0px;
    font-size: 14px;
    font-family: "Inter";
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  &--item:hover {
    background-color: #877cff;
    color: white;
  }

  &--divider {
    margin: 10px 0px 0px 0px;
    color: #c4c4c4;
    margin-left: 0px;
  }
}
